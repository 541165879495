import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/common/Layout";

const TagsPage = ({ data }) => {
  const tags = data.allGhostTag.nodes;

  return (
    <Layout>
      <h1>All Tags</h1>
      <ul>
        {tags.map(tag => (
          <li key={tag.slug}>
            <Link to={`/tag/${tag.slug}/`}>{tag.name}</Link>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  {
    allGhostTag {
      nodes {
        name
        slug
      }
    }
  }
`;

export default TagsPage;
